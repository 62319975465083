<template>
  <div id="mainslider2">
    <section class="hero-wrap style3 bg-mine-shaft">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="hero-content">
              <h1>Feeling Hungry! Order Online</h1>
              <p>
                We Bring The Best Test in Our Dishes'. In All Burger Item of 2021 We Are
                Offering 20% Flat Discount. Don't miss Out!!
              </p>
              <ul class="nav nav-tabs food-tablist" role="tablist">
                <li class="nav-item">
                  <button
                    class="nav-link active"
                    data-bs-toggle="tab"
                    data-bs-target="#tab_1"
                    type="button"
                    role="tab"
                    aria-selected="false"
                  >
                    <i class="flaticon-delivery-truck-1"></i>Delivery
                  </button>
                </li>
                <li class="nav-item">
                  <button
                    class="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#tab_2"
                    type="button"
                    role="tab"
                    aria-selected="true"
                  >
                    <i class="flaticon-take-away"></i>Takeaway
                  </button>
                </li>
              </ul>
              <div class="tab-content food-tabcontent">
                <div class="tab-pane fade show active" id="tab_1" role="tabpanel">
                  <form action="#">
                    <input type="email" placeholder="Enter Your Address" />
                    <button type="submit"><i class="flaticon-right-arrow-2"></i></button>
                  </form>
                </div>
                <div class="tab-pane fade" id="tab_2" role="tabpanel">
                  <form action="#">
                    <input type="email" placeholder="Enter Your Address" />
                    <button type="submit"><i class="flaticon-right-arrow-2"></i></button>
                  </form>
                </div>
              </div>
              <a href="search-location.html" class="find-location"
                ><i class="flaticon-gps"></i>Find My Current Location</a
              >
            </div>
          </div>
          <div class="col-lg-6">
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "Mainslider2",
  data(){
      return{
          //herobadge: require("../../assets/img/hero/badge.png"),
          //headerbottomimage: require("../../assets/img/hero/hero-img-2.png"),
          }
    }
};
</script>
<style>
.bg-mine-shaft{
    background-image: url("/assets/img/hero/hero-bg-2.jpg");
    }  
</style>
