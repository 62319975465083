<template>
  <div class="home2">
    <Header></Header>
    <Mainslider2></Mainslider2>
    <OurServices></OurServices>
    <Reservation></Reservation>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "@/views/layouts/Header.vue";
import Footer from "@/views/layouts/Footer.vue";
import Mainslider2 from "@/views/home2/Mainslider2.vue";
import OurServices from "@/views/home/OurServices.vue";
import Reservation from "@/views/home2/Reservation.vue";
export default {
  name: "home2",
  components: {
    Header,
    Mainslider2,
    OurServices,
    Reservation,
    Footer,
  },
};
</script>
