<template>
  <div id="reservation">
    <section class="reservation-wrap pt-100">
      <div class="reservation-shape bg-f"></div>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="reservation-box-wrap">
              <div class="reservation-form">
                <h4>Table Reservation</h4>
                <form action="#">
                  <div class="form-group">
                    <label for="name">Name</label>
                    <input type="text" name="name" id="name" placeholder="Jhon Doe" />
                  </div>
                  <div class="form-group">
                    <label for="person">Person</label>
                    <select name="person" id="person">
                      <option value="1">1 Persons</option>
                      <option value="2">2 Persons</option>
                      <option value="3">3 Persons</option>
                      <option value="4">4 Persons</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="date">Date</label>
                    <input type="date" name="date" id="date" />
                  </div>
                  <a href="#" class="btn style1 mt-10"
                    >Book A Table <i class="flaticon-right-arrow-2"></i
                  ></a>
                </form>
              </div>
              <div class="opening-hrs">
                <div class="overlay op-9 bg-black"></div>
                <h4>Opening Hours</h4>
                <ul class="office-schedule list-style">
                  <li>
                    <p>Monday</p>
                    <p>09:00 - 18:00</p>
                  </li>
                  <li>
                    <p>Tuesday</p>
                    <p>10:00 - 18:00</p>
                  </li>
                  <li>
                    <p>Wednesday</p>
                    <p>11:00 - 18:00</p>
                  </li>
                  <li>
                    <p>Thursday</p>
                    <p>12:00 - 18:00</p>
                  </li>
                  <li>
                    <p>Friday</p>
                    <p>14:00 - 18:00</p>
                  </li>
                  <li>
                    <p>Saturday</p>
                    <p>14:00 - 18:00</p>
                  </li>
                  <li>
                    <p>Sunday</p>
                    <p>Closed</p>
                  </li>
                </ul>
              </div>
              <div class="promotional-offer bg-f sm-none"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
// import OurFeaturedItems from './OurFeaturedItems.vue';
export default {
  //   components: { OurFeaturedItems },
  name: "featuredslider",
  data() {
    return {
    };
  },
};
</script>
